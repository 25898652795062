body {
  margin: 0;
  font-family: "lato", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.tooltip-extra-style {
  font-size: 14px !important;
}

/* LIGHT THEME */

.light-mode {
  background-color: #fff;
  color: #333;
  min-height: 100vh;
}

.container {
  max-width: 1000px !important;
}

/* DARK THEME */

.dark-mode {
  background-color: #1a1919;
  color: ghostwhite;
  min-height: 100vh;
}

.dark-mode .fab {
  color: ghostwhite;
}

.dark-mode .card {
  background-color: #1a1919;
  border-color: #939399;
}

.dark-mode .attr {
  background-color: #05bf96 !important;
}

.dark-mode .attr:hover {
  background-color: transparent !important;
}

/* CSS FOR TOGGLE BETWEEN DARK / LIGHT MODE */

.toggle {
  position: relative;
  padding: 0 4px;
  display: flex;
  align-items: center;
}

input[type="checkbox"].checkbox {
  width: 40px;
  height: 10px;
  background: #555;
  position: relative;
  border-radius: 5px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
  vertical-align: 2px;
  outline: none;
}

input[type="checkbox"].checkbox:checked + label {
  left: 30px;
}

input[type="checkbox"].checkbox:focus-visible {
  outline: solid 2px white;
}

input[type="checkbox"].checkbox + label {
  display: inline-block;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  transition: all 0.3s ease;
  cursor: pointer;
  margin-top: 10px;
  position: absolute;
  left: 2px;
  background: #fff;
  opacity: 0.9;
  background-color: cyan;
}

.toggle-container {
  display: flex;
  margin: 0 auto;
}

.toggle-container > span {
  font-size: 1.2em;
  background: none;
  border: none;
  cursor: pointer;
  transition: color 0.3s ease;
  margin-top: 4px;
  margin-right: 2px;
}

.toggle-container > span:last-child {
  margin-top: 5px;
}

.theme-toggle.dark-mode-false .react-toggle-track {
  background-color: rgba(221, 221, 0, 0.77) !important;
}

.theme-toggle.dark-mode-true .react-toggle-track {
  background-color: #0c2c59 !important;
}
